import { WixLineItem } from '../model/cartModel';

export function getProductPropertyByName(
    wixItem: WixLineItem,
    propertyName: string,
    alternativePropertyName?: string,
): string | undefined {
    if (!wixItem) return '';

    let descriptionLines = wixItem.descriptionLines;
    let descriptionLineForProperty = descriptionLines?.find(
        (descriptionLine) =>
            descriptionLine.name.original.toLowerCase() === propertyName.toLowerCase() ||
            descriptionLine.name.original.toLowerCase() === alternativePropertyName?.toLowerCase(),
    );

    return descriptionLineForProperty?.plainText?.translated;
}

export function getProductColor(wixItem: WixLineItem): string | undefined {
    if (!wixItem) return '';

    let descriptionLines = wixItem.descriptionLines;
    let descriptionLineForProperty = descriptionLines?.find((descriptionLine) => {
        let descLineName = descriptionLine.name.original.toLowerCase();
        return descLineName === 'color' || descLineName === 'колір';
    });

    return descriptionLineForProperty?.colorInfo?.translated;
}
