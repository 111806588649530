import { monobankClient } from '../clients/MonobankClient';
import { CurrencyRate } from '../model/MonobankCurrencyModels';

class MonobankService {
    constructor() {}

    public async getCurrencyRate(fromCurrencyCode: number, toCurrencyCode: number): Promise<CurrencyRate> {
        if (fromCurrencyCode == toCurrencyCode) {
            return this.getDefaultCurrencyRate(fromCurrencyCode, toCurrencyCode);
        }

        return await monobankClient.getCurrencyRate(fromCurrencyCode, toCurrencyCode);
    }

    public getDefaultCurrencyRate(fromCurrencyCode: number, toCurrencyCode: number): CurrencyRate {
        return {
            currencyCodeA: fromCurrencyCode,
            currencyCodeB: toCurrencyCode,
            date: new Date().getDate(),
            rateCross: 1,
        };
    }
}

export const monobankService = new MonobankService();
