import { Country } from './ShippingFormDataModel';

export interface IBuwlCKOContext {
    language: Language;
    sourceSystem: BuwlSourceSystem;
    country: Country;
    config: BuwlConfig;
}

export interface BuwlConfig {
    REACT_APP_WIX_CART_PL_PAGE_URL: string;
    REACT_APP_WIX_CART_PAGE_URL: string;

    [key: string]: string;
}

export enum Language {
    UA = 'UA',
    PL = 'PL',
}

export enum BuwlSourceSystem {
    BUWL_WEB_PL = 'buwlweb_PL',
    BUWL_WEB_UA = 'buwlweb_UA',
}

export enum CountryDictionary {
    Ukraine = 'UA',
    Poland = 'PL',
}
