import { Branch, City } from '../model/NovaPostApiModels';
import { WixCart } from '../model/cartModel';
import { WixOrder } from '../model/orderModel';
import { BuwlSourceSystem } from '../model/IBuwlCKOContext';
import { getAwsLambdaApiKey, getAwsLambdaUrl } from '../util/handlerUtils';

export class BuwlWixServiceClient {
    private apiKey: string = '';
    private baseUrl: string = '';

    constructor() {
        this.apiKey = getAwsLambdaApiKey();
        this.baseUrl = getAwsLambdaUrl();
    }

    public async fetchCities(query: string): Promise<City[]> {
        try {
            const response = await fetch(
                `${this.baseUrl}/nova-post/ua/cities?startsWith=${encodeURIComponent(query)}`,
                {
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                },
            );
            if (!response.ok) {
                console.error('Got not ok response, make sure your request param is ok');
                return [];
            }
            const cities = await response.json();
            return cities.data.slice(0, 20);
        } catch (error) {
            console.error(
                `Fetch query with param startsWith = ${query} to ${this.baseUrl}/nova-post/ua/cities failed with error: ${error}`,
            );
            return [];
        }
    }

    public async fetchBranches(cityRef: string, branchDes: string): Promise<Branch[]> {
        try {
            const response = await fetch(
                `${this.baseUrl}/nova-post/ua/branches?cityRef=${encodeURIComponent(cityRef)}&branchDes=${encodeURIComponent(branchDes)}`,
                {
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                },
            );
            if (!response.ok) {
                console.error('Got not ok response, make sure your request param is ok');
                return [];
            }
            const branches = await response.json();
            return branches.data.slice(0, 20);
        } catch (error) {
            console.error(
                `Fetch query with params cityRef = ${cityRef} and branchDes = ${branchDes} to ${this.baseUrl}/nova-post/ua/branches failed with error: ${error}`,
            );
            return [];
        }
    }

    public async getCart(cartId: string, sourceSystem: BuwlSourceSystem): Promise<WixCart | null> {
        try {
            console.log('get cart for sourceSystem ' + sourceSystem);
            const response = await fetch(`${this.baseUrl}/wix/carts/${cartId}?sourceSystem=${sourceSystem}`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
            });
            if (!response.ok) {
                console.error('Got not ok response, make sure your request is ok');
                return null;
            }
            const cart = await response.json();
            return cart.data as WixCart;
        } catch (error) {
            console.error(
                `Fetch query with path variable cartId = ${cartId} to ${this.baseUrl}/wix/carts/{cartId} failed with error: ${error}`,
            );
            return null;
        }
    }

    public async createOrder(order: WixOrder, sourceSystem: BuwlSourceSystem): Promise<WixOrder | null> {
        try {
            const response = await fetch(`${this.baseUrl}/wix/order?sourceSystem=${sourceSystem}`, {
                method: 'POST',
                headers: {
                    'x-api-key': this.apiKey,
                },
                body: JSON.stringify(order),
            });

            if (!response.ok) {
                console.error('Got not ok response, make sure your request is ok');
                return null;
            }

            const orderRes = await response.json();
            return orderRes.data as WixOrder;
        } catch (error) {
            console.error(
                `Create order with body order = ${order} to ${this.baseUrl}/wix/order failed with error: ${error}`,
            );
            return null;
        }
    }

    public async updateOrder(
        order: WixOrder,
        orderId: string,
        sourceSystem: BuwlSourceSystem,
    ): Promise<WixOrder | null> {
        try {
            const response = await fetch(`${this.baseUrl}/wix/order/${orderId}?sourceSystem=${sourceSystem}`, {
                method: 'PATCH',
                headers: {
                    'x-api-key': this.apiKey,
                },
                body: JSON.stringify(order),
            });

            if (!response.ok) {
                console.error('Got not ok response, make sure your request is ok');
                return null;
            }

            const orderRes = await response.json();
            return orderRes.data as WixOrder;
        } catch (error) {
            console.error(
                `Update order with body order = ${order} to ${this.baseUrl}/wix/order/${orderId} failed with error: ${error}`,
            );
            return null;
        }
    }
}

export const buwlServiceClient = new BuwlWixServiceClient();
