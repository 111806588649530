import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import ItemImage from './ItemImage';
import ProductDetails from './ProductDetails';
import PriceDisplay from './PriceDisplay';

interface OrderSummaryItemProps {
    item: {
        productName: string;
        image: {
            url: string;
        };
        price: string;
        fullPrice: string;
        quantity: number;
        braSize?: string;
        pantiesSize?: string;
        beltSize?: string;
        color?: string;
    };
}

const OrderSummaryItems: React.FC<OrderSummaryItemProps> = ({ item }) => {
    return (
        <ListItem sx={{ py: 1, px: 0 }}>
            <ItemImage url={item.image.url} alt={item.productName} />
            <ListItemText primary={item.productName} secondary={<ProductDetails item={item} />} />
            <PriceDisplay price={item.price} fullPrice={item.fullPrice} />
        </ListItem>
    );
};

export default OrderSummaryItems;
