import { Country, ShippingFormData } from '../model/ShippingFormDataModel';
import { TFunction } from 'i18next';

export function isCompliantStringAgainstLanguage(
    param: { targetName: string; targetValue: string },
    country: Country,
): boolean {
    const targetName = param.targetName;
    const targetValue = param.targetValue;

    if (targetName === 'email' || targetName === 'instagramNickname') {
        return !containsCyrillic(targetValue);
    }
    if (targetName === 'firstName' || targetName === 'lastName' || targetName === 'city') {
        return country === Country.POLAND ? isLatin(targetValue) : isCyrillic(targetValue);
    }
    if (targetName === 'novapostDep') {
        return !containsLatin(targetValue);
    }
    if (targetName === 'postalCode') {
        return !containsLatin(targetValue) && !containsCyrillic(targetValue);
    }
    if (targetName === 'address') {
        return !containsCyrillic(targetValue) || targetValue === '';
    }
    if (targetName === 'phone') {
        return !containsCyrillic(targetValue) && !containsLatin(targetValue);
    }
    return true;
}

export function getNotCompliantError(targetName: string, country: Country, t: TFunction): { [key: string]: string } {
    let errors: { [key: string]: string } = {};

    switch (targetName) {
        case 'firstName': {
            errors.firstName =
                country === Country.UKRAINE
                    ? t('languageUAErrorFirstNameMessage')
                    : t('languagePLErrorFirstNameMessage');
            break;
        }
        case 'lastName': {
            errors.lastName =
                country === Country.UKRAINE ? t('languageUAErrorLastNameMessage') : t('languagePLErrorLastNameMessage');
            break;
        }
        case 'email': {
            errors.email = t('languageErrorEmailMessage');
            break;
        }
        case 'phone': {
            errors.phone = t('languageErrorPhoneMessage');
            break;
        }
        case 'instagramNickname': {
            errors.instagramNickname = t('languageErrorInstagramMessage');
            break;
        }
        case 'city': {
            errors.city =
                country === Country.UKRAINE ? t('languageUAErrorCityMessage') : t('languagePLErrorCityMessage');
            break;
        }
        case 'address': {
            errors.address = t('languageErrorAddressMessage');
            break;
        }
        case 'novapostDep': {
            errors.novapostDep = t('languageErrorNovapostDepMessage');
            break;
        }
        case 'postalCode': {
            errors.postalCode = t('languageErrorPostalCodeMessage');
            break;
        }
    }
    return errors;
}

export function isValidEmail(value: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex for validation
    return emailRegex.test(value);
}

export function isLatin(value: string): boolean {
    const latinRegex = /^[a-zA-Z]*$/;
    return latinRegex.test(value);
}

export function containsLatin(value: string): boolean {
    const latinRegex = /[a-zA-Z]+/;
    return latinRegex.test(value);
}

export function isNumber(value: string): boolean {
    const numberRegex = /^[0-9]*$/;
    return numberRegex.test(value);
}

export function isCyrillic(value: string): boolean {
    const cyrillicRegex = /^[а-яА-ЯёЁіІєЄїЇ`]*$/;
    return cyrillicRegex.test(value);
}

export function containsCyrillic(value: string): boolean {
    const cyrillicRegex = /[а-яА-ЯёЁіІєЄїЇ`]+/;
    return cyrillicRegex.test(value);
}

export function isPhoneNumber(value: string, country: string): boolean {
    const uaPhoneRegex = /^\+380\s?\(?(\d{2})\)?-?\d{3}-?\d{2}-?\d{2}$/;
    const plPhoneRegex = /^(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)$/;
    if (country === 'Poland') {
        return plPhoneRegex.test(value);
    } else if (country === 'Ukraine') {
        return uaPhoneRegex.test(value);
    }
    return false;
}

export const validateShippingForm = (formData: ShippingFormData, t: TFunction): { [key: string]: string } => {
    let errors: { [key: string]: string } = {};

    if (!formData.email) {
        errors.email = t('emptyEmailMessage');
    } else if (!isValidEmail(formData.email)) {
        errors.email = t('invalidEmailMessage');
    }

    if (!formData.firstName) {
        errors.firstName = t('emptyFirstNameMessage');
    }

    if (!formData.lastName) {
        errors.lastName = t('emptyLastNameMessage');
    }

    if (!formData.phone) {
        errors.phone = t('emptyPhoneMessage');
    } else if (!isPhoneNumber(formData.phone, formData.country)) {
        errors.phone =
            formData.country === 'Poland' ? t('languagePLErrorPhoneMessage') : t('languageUAErrorPhoneMessage');
    }

    if (!formData.country) {
        errors.country = t('emptyCountryMessage');
    }

    if (!formData.city) {
        errors.city = t('emptyCityMessage');
    }

    if (formData.country === Country.UKRAINE && !formData.novapostDep) {
        errors.novapostDep = t('emptyNovapostDepMessage');
    }

    if (!formData.instagramNickname) {
        errors.instagramNickname = t('emptyInstagramMessage');
    }

    if (formData.country === Country.POLAND && !formData.address) {
        errors.address = t('emptyAddressMessage');
    }

    if (formData.country === Country.POLAND && !formData.postalCode) {
        errors.postalCode = t('emptyPostalCodeMessage');
    }

    return errors;
};
