export interface ShippingFormData {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    instagramNickname: string;
    country: string;
    city: string;
    postalCode: string;
    address: string;
    apartmentNumber: string;
    novapostDep: string;
}

export enum Country {
    UKRAINE = 'Ukraine',
    POLAND = 'Poland',
}
