import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NovaPostShippingAddressProps } from './NovaPostShippingAddressProps';
import { useBuwlCKOContext } from '../../../context/GlobalBuwlCkoAppContext';
import { Feature } from '../../../model/MapTilerModels';
import { mapTilerClient } from '../../../clients/MapTilerClient';

const NovaPostPolandAddressComponent: React.FC<NovaPostShippingAddressProps> = (props) => {
    const { t } = useTranslation();
    const { buwlContext } = useBuwlCKOContext();
    const [cityQuery, setCityQuery] = useState('');
    const [cityPLOptions, setCityPLOptions] = useState<Feature[]>([]);
    const [addressQuery, setAddressQuery] = useState('');
    const [addressOptions, setAddressOptions] = useState<Feature[]>([]);
    const [focusedCity, setFocusedCity] = useState(false);
    const loadingCity = focusedCity && cityPLOptions.length === 0;
    const [focusedAddress, setFocusedAddress] = useState(false);
    const loadingAddress = focusedAddress && addressOptions.length === 0;

    useEffect(() => {
        const handler = setTimeout(() => {
            if (cityQuery.trim() !== '') {
                setFocusedCity(true);
                mapTilerClient
                    .fetchCities(cityQuery, buwlContext.language === 'PL' ? 'pl' : 'uk')
                    .then((value: any) => {
                        setCityPLOptions(value);
                        return;
                    })
                    .catch((reason) => {
                        console.log(reason);
                    });
            } else {
                setAddressQuery('');
                setAddressOptions([]);
            }
        }, 500);

        return () => {
            setFocusedCity(false);
            props.formData.city = '';
            clearTimeout(handler);
        };
    }, [cityQuery]);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (addressQuery.trim() !== '') {
                setFocusedAddress(true);
                mapTilerClient
                    .fetchAddresses(addressQuery, buwlContext.language === 'PL' ? 'pl' : 'uk')
                    .then((value: any) => {
                        setAddressOptions(value);
                        return;
                    })
                    .catch((reason) => {
                        console.log(reason);
                    });
            }
        }, 500);

        return () => {
            props.formData.address = '';
            setFocusedAddress(false);
            setAddressOptions([]);
            clearTimeout(handler);
        };
    }, [addressQuery]);

    const handleCityChange = (event: React.SyntheticEvent, value: string): void => {
        setCityQuery(value);
    };

    const handleAddressChange = (event: React.SyntheticEvent, value: string): void => {
        setAddressQuery(value);
    };

    const selectPLCity = (event: React.SyntheticEvent, value: Feature | null): void => {
        if (value) {
            props.selectPLCity(event, value);
        }
    };

    const selectAddress = (event: React.SyntheticEvent, value: Feature | null): void => {
        if (value) {
            setFocusedAddress(false);
            props.selectAddress(event, value);
        }
    };

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Autocomplete
                    sx={{ pt: 2, pl: 2 }}
                    fullWidth
                    loading={loadingCity}
                    forcePopupIcon={false}
                    options={cityPLOptions}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.text}
                    onChange={selectPLCity}
                    onClose={() => {
                        setFocusedCity(false);
                    }}
                    noOptionsText={t('noAvailableCities')}
                    filterOptions={(options) => options}
                    onInputChange={handleCityChange}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ flexShrink: 0 }} {...props}>
                            <div>
                                {option.text}{' '}
                                <p style={{ marginTop: 0, marginBottom: 0, opacity: 0.5 }}>{option.context[0].text}</p>
                            </div>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('city')}
                            name="city"
                            value={props.formData.city}
                            onChange={props.handleFormChange}
                            inputProps={{
                                ...params.inputProps,
                                'data-testid': 'inputCityPl',
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loadingCity && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ pt: 2, pl: 2 }}>
                <TextField
                    required
                    fullWidth
                    label={t('postalCode')}
                    name="postalCode"
                    value={props.formData.postalCode}
                    error={!!props.formErrors.postalCode}
                    helperText={props.formErrors.postalCode}
                    onChange={props.handleFormChange}
                    variant="outlined"
                    inputProps={{
                        'data-testid': 'inputPostalCode',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ pt: 2, pl: 2 }}>
                {/*<Autocomplete*/}
                {/*    sx={{ pt: 2, pl: 2 }}*/}
                {/*    fullWidth*/}
                {/*    loading={loadingAddress}*/}
                {/*    forcePopupIcon={false}*/}
                {/*    isOptionEqualToValue={(option, value) => option.id === value.id}*/}
                {/*    options={addressOptions}*/}
                {/*    noOptionsText={t('noAvailableAddresses')}*/}
                {/*    getOptionLabel={(option) => option.place_name}*/}
                {/*    onInputChange={handleAddressChange}*/}
                {/*    onChange={selectAddress}*/}
                {/*    filterOptions={(options) => options}*/}
                {/*    onClose={() => {*/}
                {/*        setFocusedAddress(false);*/}
                {/*    }}*/}
                {/*    renderOption={(props, option) => (*/}
                {/*        <Box component="li" sx={{ flexShrink: 0 }} {...props}>*/}
                {/*            <div>{option.place_name}</div>*/}
                {/*        </Box>*/}
                {/*    )}*/}
                {/*    renderInput={(params) => (*/}
                {/*        <TextField*/}
                {/*            {...params}*/}
                {/*            label={t('address')}*/}
                {/*            name="address"*/}
                {/*            inputProps={{*/}
                {/*                ...params.inputProps,*/}
                {/*                'data-testid': 'inputAddress',*/}
                {/*            }}*/}
                {/*            value={props.formData.address}*/}
                {/*            onChange={props.handleFormChange}*/}
                {/*            InputProps={{*/}
                {/*                ...params.InputProps,*/}
                {/*                endAdornment: (*/}
                {/*                    <>*/}
                {/*                        {loadingAddress && <CircularProgress color="inherit" size={20} />}*/}
                {/*                        {params.InputProps.endAdornment}*/}
                {/*                    </>*/}
                {/*                ),*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*/>*/}
                <TextField
                    fullWidth
                    label={t('address')}
                    name="address"
                    value={props.formData.address}
                    error={!!props.formErrors.address}
                    helperText={props.formErrors.address}
                    onChange={props.handleFormChange}
                    variant="outlined"
                    inputProps={{
                        'data-testid': 'address',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ pt: 2, pl: 2 }}>
                <TextField
                    fullWidth
                    label={t('apartmentNumber')}
                    name="apartmentNumber"
                    value={props.formData.apartmentNumber}
                    error={!!props.formErrors.apartmentNumber}
                    helperText={props.formErrors.apartmentNumber}
                    onChange={props.handleFormChange}
                    variant="outlined"
                    inputProps={{
                        'data-testid': 'apartmentNumber',
                    }}
                />
            </Grid>
        </>
    );
};

export default NovaPostPolandAddressComponent;
