import React from 'react';
import { Container } from '@mui/material';
import NovaPostUkraineAddressComponent from './NovaPostUkraineAddressComponent';
import NovaPostPolandAddressComponent from './NovaPostPolandAddressComponent';
import { NovaPostShippingAddressProps } from './NovaPostShippingAddressProps';

const NovaPostShippingAddressComponent: React.FC<NovaPostShippingAddressProps> = (props) => {
    return props.country ? (
        <Container disableGutters>
            {props.country === 'Ukraine' && <NovaPostUkraineAddressComponent {...props} />}
            {props.country === 'Poland' && <NovaPostPolandAddressComponent {...props} />}
        </Container>
    ) : null;
};

export default NovaPostShippingAddressComponent;
