import React, { useEffect } from 'react';
import { Box, CircularProgress, Container, Divider, List, Typography } from '@mui/material';
import { WixLineItem } from '../../model/cartModel';
import { getProductColor, getProductPropertyByName } from '../../util/productUtils';
import { useBuwlCKOContext } from '../../context/GlobalBuwlCkoAppContext';
import { Language } from '../../model/IBuwlCKOContext';
import { useTranslation } from 'react-i18next';
import OrderSummaryItems from './OrderSummaryItems';
import PriceSummary from './PriceSummary';

interface OrderSummaryProps {
    wixItems: WixLineItem[];
    subtotal: string;
    shipping: string;
    tax: string;
    total: string;
    isCartLoading: boolean;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ wixItems, subtotal, shipping, tax, total, isCartLoading }) => {
    const { t, i18n } = useTranslation();
    const { buwlContext } = useBuwlCKOContext();

    useEffect(() => {
        if (buwlContext && buwlContext.language) {
            const languageKey = buwlContext.language;
            i18n.changeLanguage(Language[languageKey].toLowerCase());
        }
    }, [buwlContext, i18n]);

    const itemsToRender = wixItems.map((wixItem) => ({
        productName: wixItem.productName.translated,
        image: wixItem.image,
        price: wixItem.price.formattedAmount,
        fullPrice: wixItem.fullPrice.formattedAmount,
        quantity: wixItem.quantity,
        braSize: getProductPropertyByName(wixItem, 'Розмір ліфа'),
        pantiesSize: getProductPropertyByName(wixItem, 'Розмір трусиків'),
        beltSize: getProductPropertyByName(wixItem, 'Розмір пояса'),
        color: getProductColor(wixItem),
    }));

    const renderLoadingState = () => (
        <Typography sx={{ flexGrow: 1, textAlign: 'center', marginTop: '10px' }}>
            <CircularProgress color="inherit" size={30} />
        </Typography>
    );

    const renderEmptyState = () => (
        <Typography sx={{ flexGrow: 1, textAlign: 'center', marginTop: '10px' }}>{t('noCart')}</Typography>
    );

    return (
        <Container maxWidth="lg" className="SummaryComponent" sx={{ bgcolor: 'primary.main', p: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiTextField-root': { m: 1 },
                    '& .MuiButton-root': { m: 1 },
                }}
            >
                <Typography variant="h5" component="h2">
                    {t('orderSummaryInfo')}
                </Typography>
                <Typography>
                    <a
                        style={{ color: 'black' }}
                        href={
                            buwlContext.language === Language.UA
                                ? buwlContext.config.REACT_APP_WIX_CART_PAGE_URL
                                : buwlContext.config.REACT_APP_WIX_CART_PL_PAGE_URL
                        }
                    >
                        {t('changeOrder')}
                    </a>
                </Typography>
                <Divider sx={{ mt: 2 }} />
                {itemsToRender.length === 0 && isCartLoading && renderLoadingState()}
                {itemsToRender.length === 0 && !isCartLoading && renderEmptyState()}
                {itemsToRender.length !== 0 && (
                    <List disablePadding sx={{ mt: 2 }}>
                        {itemsToRender.map((item, index) => (
                            <OrderSummaryItems key={index} item={item} />
                        ))}
                        <Divider sx={{ mt: 2 }} component="li" />
                    </List>
                )}
                <PriceSummary
                    subtotal={subtotal}
                    shipping={shipping}
                    tax={tax}
                    total={total}
                    sourceSystem={buwlContext.sourceSystem}
                />
            </Box>
        </Container>
    );
};

export default OrderSummary;
