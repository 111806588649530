import React from 'react';
import { Box, Typography } from '@mui/material';

interface PriceDisplayProps {
    price: string;
    fullPrice: string;
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({ price, fullPrice }) => (
    <Box>
        {fullPrice !== price && (
            <Typography sx={{ textDecoration: 'line-through' }} variant="caption">
                {fullPrice}
            </Typography>
        )}
        <Typography sx={{ display: 'block' }} variant="body2">
            {price}
        </Typography>
    </Box>
);

export default PriceDisplay;
