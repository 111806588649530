import React from 'react';
import { useTranslation } from 'react-i18next';

interface DetailItem {
    label: string;
    value: any;
}

interface ProductDetailsProps {
    item: {
        quantity: number;
        braSize?: string;
        pantiesSize?: string;
        beltSize?: string;
        color?: string;
    };
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ item }) => {
    const { t } = useTranslation();

    const details = [
        { label: t('quantity'), value: item.quantity },
        item.braSize && { label: t('braSize'), value: item.braSize },
        item.pantiesSize && { label: t('pantiesSize'), value: item.pantiesSize },
        item.beltSize && { label: t('beltSize'), value: item.beltSize },
        item.color && { label: t('color'), value: item.color },
    ].filter(Boolean) as DetailItem[]; // Type assertion here

    return (
        <>
            {details.map((detail, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {`${detail.label}: ${detail.value}`}
                </React.Fragment>
            ))}
        </>
    );
};

export default ProductDetails;
