import { Feature } from '../model/MapTilerModels';

export class MapTilerClient {
    private apiKey: string = '';
    private baseUrl: string = '';

    constructor() {
        this.apiKey = process.env.REACT_APP_AWS_LAMBDA_API_KEY || '';
        this.baseUrl = process.env.REACT_APP_AWS_LAMBDA_URL || '';
    }

    public async fetchCities(query: string, language: string): Promise<Feature[]> {
        try {
            const response = await fetch(
                `${this.baseUrl}/map-tiler/cities?startsWith=${query}&lang=${encodeURIComponent(language)}`,
                {
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                },
            );
            if (!response.ok) {
                console.error('Got not ok response, make sure your request param is ok');
                return [];
            }
            const cities = await response.json();
            console.log(`${this.baseUrl}/map-tiler/cities?startsWith=${query}&lang=${encodeURIComponent(language)}`);
            return cities.data;
        } catch (error) {
            console.error(
                `Fetch query with param startsWith = ${query} and lang = ${language} to ${this.baseUrl}/map-tiler/cities failed with error: ${error}`,
            );
            return [];
        }
    }

    public async fetchAddresses(query: string, language: string): Promise<Feature[]> {
        try {
            const response = await fetch(
                `${this.baseUrl}/map-tiler/addresses?startsWith=${query}&lang=${encodeURIComponent(language)}`,
                {
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                },
            );
            if (!response.ok) {
                console.error('Got not ok response, make sure your request param is ok');
                return [];
            }
            const addresses = await response.json();
            console.log(`${this.baseUrl}/map-tiler/addresses?startsWith=${query}&lang=${encodeURIComponent(language)}`);
            return addresses.data;
        } catch (error) {
            console.error(
                `Fetch query with param startsWith = ${query} and lang = ${language} to ${this.baseUrl}/map-tiler/addresses failed with error: ${error}`,
            );
            return [];
        }
    }
}

export const mapTilerClient = new MapTilerClient();
