const AWS_LAMBDA_URL = process.env.REACT_APP_AWS_LAMBDA_URL || '';
const AWS_LAMBDA_API_KEY = process.env.REACT_APP_AWS_LAMBDA_API_KEY || '';

export function getAwsLambdaUrl(): string {
    return AWS_LAMBDA_URL;
}

export function getAwsLambdaApiKey(): string {
    return AWS_LAMBDA_API_KEY;
}
