import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { Country, ShippingFormData } from '../../model/ShippingFormDataModel';
import { Language } from '../../model/IBuwlCKOContext';
import { useBuwlCKOContext } from '../../context/GlobalBuwlCkoAppContext';
import {
    getNotCompliantError,
    isCompliantStringAgainstLanguage,
    isPhoneNumber,
    isValidEmail,
} from '../../util/validationUtils';
import { useTranslation } from 'react-i18next';
import { Branch, City } from '../../model/NovaPostApiModels';
import NovaPostShippingAddressComponent from './novaPost/NovaPostShippingAddressComponent';
import { Feature } from '../../model/MapTilerModels';
import { pricingService } from '../../service/PricingService';

interface ShippingAddressFormProps {
    onValidShippingSubmit: () => void;
    formData: ShippingFormData;
    setFormData: (data: ShippingFormData) => void;
    setShippingCost: (price: number) => void;
}

const ShippingAddressFormComponent: React.FC<ShippingAddressFormProps> = ({
    onValidShippingSubmit,
    formData,
    setFormData,
    setShippingCost,
}) => {
    const { t, i18n } = useTranslation();
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const { buwlContext, updateContextValue } = useBuwlCKOContext();

    useEffect(() => {
        if (buwlContext && buwlContext.language) {
            const languageKey = buwlContext.language;
            i18n.changeLanguage(Language[languageKey].toLowerCase());
        }
    }, [buwlContext]);

    const selectCity = (event: React.SyntheticEvent, value: City | null): void => {
        if (value) {
            setFormData({
                ...formData,
                city: value.Description,
            });
        }
    };

    const selectPLCity = (event: React.SyntheticEvent, value: Feature | null): void => {
        if (value) {
            setFormData({
                ...formData,
                city: value.text,
            });
        }
    };

    const selectAddress = (event: React.SyntheticEvent, value: Feature | null): void => {
        if (value) {
            setFormData({
                ...formData,
                address: value.place_name,
            });
        }
    };

    const selectBranch = (event: React.SyntheticEvent, value: Branch | null): void => {
        if (value) {
            setFormData({
                ...formData,
                novapostDep: value.Description,
                postalCode: value.PostalCodeUA,
            });
        }
    };

    /**
     * Adres jest wymagany
     * Nazwa użytkownika na Instagramie jest wymagana
     * Oddział Nowej Poczty jest wymagany
     * Kraj jest wymagany
     * Numer telefonu jest wymagany
     * Nazwisko jest wymagane
     */
    const validateForm = () => {
        let errors: { [key: string]: string } = {};

        if (!formData.email) {
            errors.email = t('emptyEmailMessage');
        } else if (!isValidEmail(formData.email)) {
            errors.email = t('invalidEmailMessage');
        }

        if (!formData.firstName) {
            errors.firstName = t('emptyFirstNameMessage');
        }

        if (!formData.lastName) {
            errors.lastName = t('emptyLastNameMessage');
        }

        if (!formData.phone) {
            errors.phone = t('emptyPhoneMessage');
        } else if (!isPhoneNumber(formData.phone, formData.country)) {
            formData.country === 'Poland'
                ? (errors.phone = t('languagePLErrorPhoneMessage'))
                : (errors.phone = t('languageUAErrorPhoneMessage'));
        }

        if (!formData.country) {
            errors.country = t('emptyCountryMessage');
        }

        if (!formData.city) {
            errors.city = t('emptyCityMessage');
        }

        if (formData.country === Country.UKRAINE && !formData.novapostDep) {
            errors.novapostDep = "Відділення Нової Пошти є обов'язковим полем для України";
        }

        if (!formData.instagramNickname) {
            errors.instagramNickname = t('emptyInstagramMessage');
        }

        if (formData.country === Country.POLAND && !formData.address) {
            errors.address = t('emptyAddressMessage');
        }

        if (formData.country === Country.POLAND && !formData.postalCode) {
            errors.postalCode = t('emptyPostalCodeMessage');
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        buwlContext.country = formData.country.toUpperCase() === 'POLAND' ? Country.POLAND : Country.UKRAINE;
        if (
            isCompliantStringAgainstLanguage(
                { targetName: event.target.name, targetValue: event.target.value },
                buwlContext.country,
            )
        ) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
            setFormErrors({});
        } else {
            const errors: { [key: string]: string } = getNotCompliantError(event.target.name, buwlContext.country, t);
            setFormErrors(errors);
        }
    };

    const handleChangeForSelect = async (event: SelectChangeEvent) => {
        const name = event.target.name;
        const value = event.target.value;
        if (name === 'country' && value !== formData.country) {
            setFormData({
                ...formData,
                firstName: '',
                lastName: '',
                city: '',
                postalCode: '',
                address: '',
                country: value,
            });
            setShippingCost(await pricingService.getShippingCost(value, buwlContext.sourceSystem));
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (validateForm()) {
            console.log('Shipping Data:', formData);
            onValidShippingSubmit();
        } else {
            console.log('Validation errors:', formErrors);
        }
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiTextField-root': { m: 1 },
                    '& .MuiButton-root': { m: 1 },
                }}
                pr={{ xs: 0, sm: 0, md: 2, lg: 4, xl: 4 }}
            >
                <Paper elevation={3}>
                    <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                        {t('typography')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl
                                fullWidth
                                error={!!formErrors.country}
                                sx={{ m: 1 }} // This applies the same margin as the TextField components
                            >
                                <InputLabel id="country-label" required>
                                    {t('country')}
                                </InputLabel>
                                <Select
                                    required
                                    labelId="country-label"
                                    label="Країна"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChangeForSelect}
                                    variant="outlined"
                                    inputProps={{
                                        'data-testid': 'inputCountry',
                                    }}
                                >
                                    <MenuItem value={'Ukraine'}>{t('UA')}</MenuItem>
                                    <MenuItem value={'Poland'}>{t('PL')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                label={t('firstName')}
                                name="firstName"
                                value={formData.firstName}
                                error={!!formErrors.firstName}
                                helperText={formErrors.firstName}
                                onChange={handleFormChange}
                                variant="outlined"
                                inputProps={{ 'data-testid': 'inputFirstName' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                label={t('lastName')}
                                name="lastName"
                                value={formData.lastName}
                                error={!!formErrors.lastName}
                                helperText={formErrors.lastName}
                                onChange={handleFormChange}
                                variant="outlined"
                                inputProps={{ 'data-testid': 'inputLastName' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                label={t('phoneNumber')}
                                name="phone"
                                value={formData.phone}
                                error={!!formErrors.phone}
                                helperText={formErrors.phone}
                                onChange={handleFormChange}
                                variant="outlined"
                                inputProps={{ 'data-testid': 'inputPhoneNumber' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label={t('instagramNickname')}
                                name="instagramNickname"
                                value={formData.instagramNickname}
                                error={!!formErrors.instagramNickname}
                                helperText={formErrors.instagramNickname}
                                onChange={handleFormChange}
                                variant="outlined"
                                inputProps={{ 'data-testid': 'inputInstagramNickname' }}
                            />
                        </Grid>
                        <NovaPostShippingAddressComponent
                            formData={formData}
                            handleFormChange={handleFormChange}
                            formErrors={formErrors}
                            selectCity={selectCity}
                            selectPLCity={selectPLCity}
                            selectBranch={selectBranch}
                            selectAddress={selectAddress}
                            country={formData.country}
                        />
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                label={t('confirmEmail')}
                                name="email"
                                value={formData.email}
                                error={!!formErrors.email}
                                helperText={formErrors.email}
                                onChange={handleFormChange}
                                variant="outlined"
                                inputProps={{ 'data-testid': 'inputEmail' }}
                            />
                        </Grid>
                        <Grid item xs={12} className="GoToPayment">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="GoToPaymentButton"
                                data-testid="btnProceedToPayment"
                            >
                                {t('proceedToPayment')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
};

export default ShippingAddressFormComponent;
