import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useBuwlCKOContext } from '../../context/GlobalBuwlCkoAppContext';
import { Language } from '../../model/IBuwlCKOContext';
import { useTranslation } from 'react-i18next';

interface UAFopCardInfoProps {}

const UAFopCardInfoComponent: React.FC<UAFopCardInfoProps> = () => {
    const { t, i18n } = useTranslation();
    const { buwlContext, updateContextValue } = useBuwlCKOContext();

    useEffect(() => {
        if (buwlContext && buwlContext.language) {
            const languageKey = buwlContext.language;
            i18n.changeLanguage(Language[languageKey].toLowerCase());
        }
    }, [buwlContext]);

    // Split the card number into groups for display
    const formatCardNumber = (number: string): string => {
        return number.match(/.{1,4}/g)?.join(' ') ?? '';
    };

    const FOP_CARD_NUMBER = '4035200041086510';
    /**
     * Одержувач
     * ФОП ФОП Призиглей Анастасія Орестівна
     * IBAN
     * UA463220010000026003310097880
     * ЄДРПОУ
     * 3618603064
     * Призначення платежу
     * Призначення платежу вказується згідно з актом виконаних робіт, інвойсом або контрактом.
     *
     * Реквізити Банку
     * Акціонерне Товариство УНІВЕРСАЛ БАНК
     * МФО 322001
     * ЄДРПОУ Банку 21133352
     */
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                {t('payment')}
            </Typography>
        </div>
    );
};

export default UAFopCardInfoComponent;
