import { ShippingFormData } from '../model/ShippingFormDataModel';
import { WixOrder } from '../model/orderModel';
import { WixCart } from '../model/cartModel';
import { Payment, PaymentResponse } from '../model/WixTransactionsModels';
import { InvoiceRequest, Item } from '../model/MonoAcquiringModels';
import { BuwlSourceSystem, Language } from '../model/IBuwlCKOContext';
import { formatAmount } from './currencyUtils';

const BASE_URL = process.env.REACT_APP_AWS_LAMBDA_URL || '';
const BASE_UA_THANK_YOU_PAGE = 'https://www.blessed-undressed.com/thank-you-page';
const BASE_PL_THANK_YOU_PAGE = 'https://www.blessed-undressed.pl/thank-you-page';

export default class Mapper {
    public static createOrder(
        userInfo: ShippingFormData,
        cart: WixCart,
        checkoutId: string,
        sourceSystem: BuwlSourceSystem,
    ): WixOrder {
        const shippingCost = cart.cart.selectedShippingOption.price;
        const formattedShippingCost = formatAmount(shippingCost, sourceSystem);

        const totalCost = parseFloat(cart.cart.subtotal?.amount!) + cart.cart.selectedShippingOption.price;
        console.log(`Total cost ${totalCost}`);
        const formattedTotalCost = formatAmount(totalCost, sourceSystem);
        return {
            order: {
                status: 'INITIALIZED',
                paymentStatus: 'NOT_PAID',
                buyerInfo: {
                    email: userInfo.email,
                    memberId: cart.cart.buyerInfo?.memberId,
                    contactId: cart.cart.buyerInfo.contactId,
                },
                buyerNote:
                    `instagram: ${userInfo.instagramNickname}\n` +
                    (!cart.cart?.buyerNote || cart.cart.buyerNote === 'undefined' ? '' : cart.cart.buyerNote),
                priceSummary: {
                    subtotal: {
                        amount: cart.cart.subtotal?.amount,
                        formattedAmount: cart.cart.subtotal?.formattedAmount,
                    },
                    shipping: {
                        amount: shippingCost.toFixed(2),
                        formattedAmount: formattedShippingCost,
                    },
                    tax: {
                        amount: '0',
                        formattedAmount: '0,00₴',
                    },
                    discount: {
                        amount: '0',
                        formattedAmount: '0,00₴',
                    },
                    total: {
                        amount: totalCost.toFixed(2),
                        formattedAmount: formattedTotalCost,
                    },
                },
                billingInfo: {
                    address: {
                        country: userInfo.country === 'Ukraine' ? 'UA' : 'PL',
                        countryFullname: userInfo.country,
                        city: userInfo.city,
                        addressLine: this.getAddress(userInfo),
                        postalCode: userInfo.postalCode,
                    },
                    contactDetails: {
                        firstName: userInfo.firstName,
                        lastName: userInfo.lastName,
                        phone: userInfo.phone,
                    },
                },
                shippingInfo: {
                    title: 'Доставка поштою',
                    cost: {
                        price: {
                            amount: '0',
                            formattedAmount: '0,00₴',
                        },
                        totalPriceAfterTax: {
                            amount: '0',
                            formattedAmount: '0,00₴',
                        },
                        totalPriceBeforeTax: {
                            amount: '0',
                            formattedAmount: '0,00₴',
                        },
                    },
                    logistics: {
                        shippingDestination: {
                            address: {
                                country: userInfo.country === 'Ukraine' ? 'UA' : 'PL',
                                countryFullname: userInfo.country,
                                city: userInfo.city,
                                addressLine: userInfo.novapostDep || this.getAddress(userInfo),
                                postalCode: userInfo.postalCode,
                            },
                            contactDetails: {
                                firstName: userInfo.firstName,
                                lastName: userInfo.lastName,
                                phone: userInfo.phone,
                            },
                        },
                    },
                },
                lineItems: cart.cart.lineItems.map((value) => {
                    return {
                        id: value._id,
                        productName: {
                            original: value.productName.original,
                        },
                        taxDetails: {
                            taxRate: '0.0',
                            totalTax: {
                                amount: '0',
                                formattedAmount: '0,00₴',
                            },
                        },
                        catalogReference: {
                            catalogItemId: value.catalogReference.catalogItemId,
                            appId: value.catalogReference.appId,
                            options: {
                                options: value.catalogReference.options.options,
                                variantId: value.catalogReference.options.variantId,
                            },
                        },
                        quantity: value.quantity,
                        descriptionLines: value.descriptionLines.map((desc) => {
                            return {
                                name: {
                                    original: desc.name.original,
                                    translated: desc.name.translated,
                                },
                                plainText: {
                                    original: desc.plainText?.original || desc.colorInfo?.original,
                                    translated: desc.plainText?.translated || desc.colorInfo?.translated,
                                },
                                colorInfo: {
                                    original: desc.colorInfo?.original,
                                    translated: desc.colorInfo?.translated,
                                    code: desc.colorInfo?.code,
                                },
                                lineType: desc.lineType,
                            };
                        }),
                        image: {
                            url: value.image.url,
                            id: value.image._id,
                            height: value.image.height,
                            width: value.image.width,
                            filename: value.image.filename,
                        },
                        physicalProperties: {
                            sku: value.physicalProperties.sku,
                            shippable: value.physicalProperties.shippable,
                        },
                        itemType: {
                            preset: value.itemType.preset,
                        },
                        price: {
                            amount: value.price.amount,
                            formattedAmount: value.price.formattedAmount,
                        },
                        paymentOption: value.paymentOption,
                        lineItemPrice: {
                            amount: value.priceBeforeDiscounts.amount,
                            formattedAmount: value.priceBeforeDiscounts.formattedAmount,
                        },
                    };
                }),
                channelInfo: {
                    type: 'WEB',
                },
                appliedDiscounts: cart.cart.appliedDiscounts,
                weightUnit: cart.cart.weightUnit,
                currency: cart.cart.currency,
                taxIncludedInPrices: cart.cart.taxIncludedInPrices,
                buyerLanguage: cart.cart.buyerLanguage,
                checkoutId: checkoutId,
            },
        } as WixOrder;
    }

    private static getAddress(userInfo: ShippingFormData): string {
        return userInfo.apartmentNumber ? userInfo.address + ' ' + userInfo.apartmentNumber : userInfo.address;
    }

    public static createPayments(order: WixOrder): Payment[] {
        return [
            {
                createDate: new Date().toISOString().slice(0, 10),
                amount: {
                    amount: order.order.priceSummary.total?.amount || '0',
                },
                refundDisabled: false,
                regularPaymentDetails: {
                    paymentOrderId: order.order.id,
                    offlinePayment: false,
                    paymentMethod: 'CreditCard',
                    status: 'UNDEFINED',
                },
            },
        ];
    }

    public static createInvoiceRequest(
        order: WixOrder,
        transaction: PaymentResponse,
        language: Language,
        sourceSystem: BuwlSourceSystem,
    ): InvoiceRequest {
        return {
            amount: parseFloat(order.order.priceSummary.total.amount || '0') * 100,
            ccy: order.order.currency === 'UAH' ? 980 : 985,
            merchantPaymInfo: {
                reference:
                    'payment' + transaction.paymentsIds[0] + 'order' + transaction.orderTransactions.orderId + 'rate',
                destination: 'Wix order',
                basketOrder: order.order.lineItems.map((value): Item => {
                    return {
                        name: value.productName.original,
                        qty: value.quantity,
                        sum: parseFloat(value.price.amount) * 100,
                        icon: value.image.url,
                        unit: language === 'UA' ? 'од.' : 'ilość',
                    };
                }),
            },
            redirectUrl: `${
                sourceSystem === BuwlSourceSystem.BUWL_WEB_UA ? BASE_UA_THANK_YOU_PAGE : BASE_PL_THANK_YOU_PAGE
            }/${transaction.orderTransactions.orderId}?appSectionParams=%7B"objectType"%3A"order"%2C"origin"%3A"checkout"%7D`,
            webHookUrl: `${BASE_URL}/mono/merchant/invoice/updated-status-webhook?sourceSystem=${sourceSystem}`,
        };
    }
}
