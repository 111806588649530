import { CurrencyRate } from '../model/MonobankCurrencyModels';
import { BuwlSourceSystem } from '../model/IBuwlCKOContext';

export const UAH_CURRENCY_CODE = 980;
export const UAH_CURRENCY_SYMBOL = '₴';

export const PLN_CURRENCY_CODE = 985;

export const PLN_CURRENCY_SYMBOL = 'zł';

export const SYSTEM_TO_CURRENCY_SYMBOL: { [key: string]: string } = {
    buwlweb_UA: UAH_CURRENCY_SYMBOL,
    buwlweb_PL: PLN_CURRENCY_SYMBOL,
};

export function convertAmount(amount: number, currencyRate: CurrencyRate): number {
    if (currencyRate.rateCross) {
        return amount * currencyRate.rateCross;
    } else if (currencyRate.rateBuy) {
        return amount * currencyRate.rateBuy;
    } else if (currencyRate.rateSell) {
        return amount * currencyRate.rateSell;
    }

    return amount;
}

export function formatAmount(amount: number, sourceSystem: BuwlSourceSystem): string {
    const currencySymbol = getCurrencySymbol(sourceSystem);
    return `${amount.toFixed(2)}${currencySymbol}`;
}

function getCurrencySymbol(sourceSystem: BuwlSourceSystem): string {
    return SYSTEM_TO_CURRENCY_SYMBOL[sourceSystem];
}
