import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBuwlCKOContext } from '../../context/GlobalBuwlCkoAppContext';
import { BuwlSourceSystem, Language } from '../../model/IBuwlCKOContext';
import { AppBar, Toolbar, Typography } from '@mui/material';

function BuwlHeader() {
    const { i18n } = useTranslation();
    const { buwlContext, updateContextValue } = useBuwlCKOContext();

    useEffect(() => {
        if (buwlContext && buwlContext.language) {
            const languageKey = buwlContext.language;
            i18n.changeLanguage(Language[languageKey].toLowerCase());
        }
    }, [buwlContext]);

    const handleChangeLocalization = async () => {
        const newLanguage = buwlContext.language === Language.PL ? Language.UA : Language.PL;

        const currentURL = new URL(window.location.href);
        const queryParams = currentURL.search;
        let newLocalizationURL = window.location.href.replace(queryParams, '');
        newLocalizationURL += await createUrlParams(currentURL.searchParams, newLanguage, buwlContext.sourceSystem);

        window.location.replace(newLocalizationURL);
    };

    const createUrlParams = async (
        searchParams: URLSearchParams,
        language: Language,
        sourceSystem: BuwlSourceSystem = BuwlSourceSystem.BUWL_WEB_UA,
    ): Promise<string> => {
        let result = '?';
        result += searchParams.get('cartId') ? 'cartId=' + searchParams.get('cartId') + '&' : '';
        result += searchParams.get('checkoutId') ? 'checkoutId=' + searchParams.get('checkoutId') + '&' : '';
        result += language === 'PL' ? 'lang=PL' : 'lang=UA';
        result += '&sourceSystem=' + sourceSystem;
        return result;
    };

    const handleLogoClick = () => {
        const targetUrl =
            buwlContext.language === 'UA'
                ? buwlContext.config.REACT_APP_WIX_CART_PAGE_URL
                : buwlContext.config.REACT_APP_WIX_CART_PL_PAGE_URL;

        // Only navigate if the target URL is different from the current URL
        if (window.location.href !== targetUrl) {
            console.log('Redirecting to cart page');
            window.location.replace(targetUrl);
        }
    };

    return (
        <AppBar position="static">
            <Toolbar
                variant="regular"
                sx={{ backgroundColor: '#f6f6f6', display: 'flex', justifyContent: 'space-between' }}
            >
                <img
                    onClick={handleLogoClick}
                    src={`${process.env.PUBLIC_URL}/BUWL_LOGO_CLEAN.svg`}
                    alt="Logo"
                    style={{ maxHeight: '40px', cursor: 'pointer' }}
                />
                <Typography onClick={handleChangeLocalization} sx={{ cursor: 'pointer' }}>
                    {buwlContext.language === 'PL' ? 'UA' : 'PL'}
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default BuwlHeader;
