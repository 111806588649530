import { OrderTransactions, Payment, PaymentResponse } from '../model/WixTransactionsModels';
import { BuwlSourceSystem } from '../model/IBuwlCKOContext';

export default class PaymentTransactionsClient {
    private apiKey: string = '';
    private baseUrl: string = '';

    constructor() {
        this.apiKey = process.env.REACT_APP_AWS_LAMBDA_API_KEY || '';
        this.baseUrl = process.env.REACT_APP_AWS_LAMBDA_URL || '';
    }

    public async createPayment(
        orderId: string,
        payments: Payment[],
        sourceSystem: BuwlSourceSystem,
    ): Promise<PaymentResponse> {
        try {
            const response = await fetch(
                `${this.baseUrl}/payments/orders/${orderId}/add-payment?sourceSystem=${sourceSystem}`,
                {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                    body: JSON.stringify({ payments: payments }),
                },
            );

            if (!response.ok) {
                console.error('Got not ok response, make sure your request is ok');
                return {} as PaymentResponse;
            }

            const transactionsInfo = await response.json();
            return transactionsInfo.data as PaymentResponse;
        } catch (error) {
            console.error(
                `Fetch query with path param orderId = ${orderId} and body = ${payments} to ${this.baseUrl}/payments/orders/{orderId}/add-payment failed with error: ${error}`,
            );
            return {} as PaymentResponse;
        }
    }

    public async updatePaymentStatus(
        orderId: string,
        paymentId: string,
        status: string,
        sourceSystem: BuwlSourceSystem,
    ): Promise<OrderTransactions> {
        try {
            const response = await fetch(
                `${this.baseUrl}/payments/${paymentId}/orders/${orderId}/update-payment-transaction-status?sourceSystem=${sourceSystem}`,
                {
                    method: 'POST',
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                    body: JSON.stringify({ status }),
                },
            );

            if (!response.ok) {
                console.error('Got not ok response, make sure your request is ok');
                return {} as OrderTransactions;
            }

            const transactions = await response.json();
            return transactions.data as OrderTransactions;
        } catch (error) {
            console.error(
                `Fetch query with path param orderId = ${orderId}, paymentId = ${paymentId} and body = ${{ status }} to ${this.baseUrl}/ failed with error: ${error}`,
            );
            return {} as OrderTransactions;
        }
    }
}

export const buwlTransactionsClient = new PaymentTransactionsClient();
