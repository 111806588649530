import React, { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Link,
    Paper,
} from '@mui/material';
import { useBuwlCKOContext } from '../context/GlobalBuwlCkoAppContext';
import UAFopCardInfoComponent from './payment/UAFopCardInfoComponent';
import { Language } from '../model/IBuwlCKOContext';
import { Country } from '../model/ShippingFormDataModel';
import PLSepaPaymentInfoComponent from './payment/PLSepaPaymentInfoComponent';
import { useTranslation } from 'react-i18next';

interface SubmitComponentProps {
    onSubmit: () => void; // Handler for when the submit button is clicked
    isLoading: boolean;
    errorCode: string;
}

const SubmitComponent: React.FC<SubmitComponentProps> = ({ onSubmit, isLoading, errorCode }) => {
    const { t, i18n } = useTranslation();
    const { buwlContext, updateContextValue } = useBuwlCKOContext();
    const [isRegulationsAccepted, setIsRegulationAccepted] = useState(false);

    const handleRegulationsAcceptedCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsRegulationAccepted(event.target.checked);
    };

    useEffect(() => {
        if (buwlContext && buwlContext.language) {
            const languageKey = buwlContext.language;
            i18n.changeLanguage(Language[languageKey].toLowerCase());
        }
    }, [buwlContext]);

    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiTextField-root': { m: 1 },
                    '& .MuiButton-root': { m: 1 },
                }}
                pr={{ xs: 0, sm: 0, md: 2, lg: 4, xl: 4 }}
            >
                <Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                    {buwlContext.country === Country.POLAND && (
                        <PLSepaPaymentInfoComponent></PLSepaPaymentInfoComponent>
                    )}
                    {buwlContext.country === Country.UKRAINE && <UAFopCardInfoComponent></UAFopCardInfoComponent>}

                    <FormControlLabel
                        sx={{ mt: 1 }}
                        control={
                            <Checkbox
                                checked={isRegulationsAccepted}
                                onChange={handleRegulationsAcceptedCheckboxChange}
                                name="paymentMadeCheckbox"
                                color="success"
                                inputProps={
                                    {
                                        'data-testid': 'inputTermsAgreementCheckbox',
                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                }
                            />
                        }
                        label={
                            buwlContext.language === Language.PL ? (
                                <>
                                    {'Akceptuję '}
                                    {/*<Link*/}
                                    {/*    href="https://www.facebook.com/help/instagram/561290520611666"*/}
                                    {/*    target="_blank"*/}
                                    {/*    rel="noopener noreferrer"*/}
                                    {/*    color={'textSecondary'}*/}
                                    {/*>*/}
                                    {/*    Warunki i postanowienia,*/}
                                    {/*</Link>{' '}*/}
                                    <Link
                                        href="https://www.blessed-undressed.pl/privacy-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color={'textSecondary'}
                                    >
                                        Politykę prywatności
                                    </Link>
                                </>
                            ) : (
                                <>
                                    {'Я приймаю '}
                                    <Link
                                        href="https://www.blessed-undressed.com/delivery"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color={'textSecondary'}
                                    >
                                        Умови та положення,
                                    </Link>{' '}
                                    <Link
                                        href="https://www.blessed-undressed.com/privacy-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color={'textSecondary'}
                                    >
                                        Політику конфіденційності
                                    </Link>
                                </>
                            )
                        }
                    />
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        {!isLoading && !errorCode && (
                            <Button
                                sx={{ fontFamily: '"Forum", serif' }}
                                type="submit"
                                onClick={onSubmit}
                                fullWidth
                                variant="contained"
                                color="primary"
                                data-testid="btnSubmitOrder"
                                disabled={!isRegulationsAccepted}
                            >
                                {t('onlineOrder')}
                            </Button>
                        )}
                        <React.Fragment>{isLoading && <CircularProgress color="inherit" size={30} />}</React.Fragment>
                        {errorCode && <Alert severity="error">{t(errorCode)}</Alert>}
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default SubmitComponent;
