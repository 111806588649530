import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n/config';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
import { BuwlCKOContextProvider } from './context/GlobalBuwlCkoAppContext';
import App from './App';

let themeTool = createTheme();

const theme = createTheme({
    typography: {
        fontFamily: ['"Forum", serif'].join(','),
        fontSize: 15,
        h1: {
            fontFamily: ['"Cormorant Garamond", serif'].join(','),
            fontWeight: 400,
            fontStyle: 'normal',
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: ['"Cormorant Garamond", serif'].join(','),
            fontWeight: 400,
            fontStyle: 'normal',
            textTransform: 'uppercase',
        },
        h3: {
            fontFamily: ['"Cormorant Garamond", serif'].join(','),
            fontWeight: 400,
            fontStyle: 'normal',
            textTransform: 'uppercase',
        },
        h4: {
            fontFamily: ['"Cormorant Garamond", serif'].join(','),
            fontWeight: 400,
            fontStyle: 'normal',
            textTransform: 'uppercase',
        },
        h5: {
            fontFamily: ['"Cormorant Garamond", serif'].join(','),
            fontWeight: 400,
            fontStyle: 'normal',
            textTransform: 'uppercase',
            fontSize: '1.5rem',
        },
        h6: {
            fontFamily: ['"Cormorant Garamond", serif'].join(','),
            fontWeight: 400,
            fontStyle: 'normal',
            textTransform: 'uppercase',
        },
    },
    palette: {
        primary: {
            main: '#f6f6f6', // Replace with your custom color
        },
        // You can also customize other theme aspects like typography, secondary color, etc.
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '&.MuiToolbar-gutters': {
                        [themeTool.breakpoints.down('sm')]: {
                            paddingInline: '32px',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.GoToPaymentButton': {
                        marginLeft: 0,
                    },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '&.GoToPayment': {
                        paddingLeft: '16px',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.MuiFormControl-root': {
                        marginInline: 0,
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    // Style for the root element
                    '&.MainComponent': {
                        marginTop: '2rem',
                        [themeTool.breakpoints.down('sm')]: {
                            marginTop: '0rem',
                        },
                    },
                    '&.SummaryComponent': {
                        marginTop: '2rem',
                        [themeTool.breakpoints.down('sm')]: {
                            marginTop: '1rem',
                        },
                    },
                    marginBottom: '10px',
                },
            },
        },
        MuiInputLabel: {
            // Target the InputLabel component
            styleOverrides: {
                root: {
                    // Style for the root element
                    '&.Mui-focused': {
                        color: 'darkgrey', // Color when the input is focused
                    },
                    '&.MuiInputLabel-shrink': {
                        color: 'grey', // Color when the label is shrunk (e.g., on hover)
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: themeTool.spacing(4),
                    [themeTool.breakpoints.down('sm')]: {
                        padding: themeTool.spacing(0),
                        boxShadow: 'none',
                    },
                    '&.MuiMenu-paper': {
                        padding: themeTool.spacing(0),
                    },
                    '&.MuiAutocomplete-paper': {
                        padding: themeTool.spacing(0),
                    },
                    '&.MuiAppBar-root': {
                        padding: themeTool.spacing(0),
                    },
                },
            },
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ThemeProvider theme={theme}>
        <React.StrictMode>
            <BuwlCKOContextProvider>
                <App />
            </BuwlCKOContextProvider>
        </React.StrictMode>
    </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
