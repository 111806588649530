import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BuwlSourceSystem } from '../../model/IBuwlCKOContext';

interface PriceSummaryProps {
    subtotal: string;
    shipping: string;
    tax: string;
    total: string;
    sourceSystem: BuwlSourceSystem;
}

const PriceSummary: React.FC<PriceSummaryProps> = ({ subtotal, shipping, tax, total, sourceSystem }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body1">{t('sum')}</Typography>
                <Typography sx={{ fontFamily: '"Forum", serif' }} variant="subtitle1">
                    {subtotal}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body1">{t('delivery')}</Typography>
                <Typography sx={{ fontFamily: '"Forum", serif' }} variant="subtitle1">
                    {shipping}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Typography variant="h6">{t('total')}</Typography>
                <Typography sx={{ fontFamily: '"Forum", serif', textTransform: 'lowercase' }} variant="h6">
                    {total}
                </Typography>
            </Box>

            {sourceSystem === BuwlSourceSystem.BUWL_WEB_PL && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Typography variant="body2">{t('PLN_Payment_Note')}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default PriceSummary;
