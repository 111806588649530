import React from 'react';

interface ItemImageProps {
    url: string;
    alt: string;
}

const ItemImage: React.FC<ItemImageProps> = ({ url, alt }) => (
    <img src={url} alt={alt} style={{ width: '70px', height: '100px', marginRight: '15px' }} />
);

export default ItemImage;
