import { getAwsLambdaApiKey, getAwsLambdaUrl } from '../util/handlerUtils';
import { CurrencyRate } from '../model/MonobankCurrencyModels';

/** Client for calling BuwlServiceAPI for dealing with currency rates */
export class MonobankClient {
    private apiKey: string = '';
    private baseUrl: string = '';

    /**
     * sets value form .env file
     */
    constructor() {
        this.apiKey = getAwsLambdaApiKey();
        this.baseUrl = getAwsLambdaUrl();
    }

    public async getCurrencyRate(fromCurrency: number, toCurrency: number): Promise<CurrencyRate> {
        try {
            const response = await fetch(
                `${this.baseUrl}/mono/bank/currency?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}`,
                {
                    method: 'GET',
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                },
            );

            if (!response.ok) {
                throw new Error('Failed to get currency rate');
            }

            const currencyRate = await response.json();
            return currencyRate.data as CurrencyRate;
        } catch (error) {
            console.error(
                `Fetch query  to ${this.baseUrl}/mono/bank/currency?fromCurrency=${fromCurrency}&toCurrency=${toCurrency} failed with error: ${error}`,
            );
            return {} as CurrencyRate;
        }
    }
}

export const monobankClient = new MonobankClient();
