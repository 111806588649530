import { BuwlSourceSystem } from '../model/IBuwlCKOContext';
import { convertAmount, formatAmount, PLN_CURRENCY_CODE, UAH_CURRENCY_CODE } from '../util/currencyUtils';
import { monobankService } from './MonobankService';

class PricingService {
    private readonly shippingCost: number;
    private readonly defaultShippingCost: number;
    constructor() {
        this.shippingCost = 35;
        this.defaultShippingCost = 0;
    }

    public async getShippingCost(country: string, sourceSystem: BuwlSourceSystem): Promise<number> {
        if (country == 'Ukraine') {
            return this.defaultShippingCost;
        }

        return await this.exchangeRate(this.shippingCost, sourceSystem);
    }

    private async exchangeRate(amount: number, sourceSystem: BuwlSourceSystem): Promise<number> {
        let toCurrency = PLN_CURRENCY_CODE;

        if (sourceSystem == BuwlSourceSystem.BUWL_WEB_UA) {
            toCurrency = UAH_CURRENCY_CODE;
        }

        const currencyRate = await monobankService.getCurrencyRate(PLN_CURRENCY_CODE, toCurrency);
        return convertAmount(amount, currencyRate);
    }
}

export const pricingService = new PricingService();
