import { InvoiceRequest, InvoiceResponse } from '../model/MonoAcquiringModels';
import { getAwsLambdaApiKey, getAwsLambdaUrl } from '../util/handlerUtils';

/** Client for calling BuwlServiceAPI for dealing with invoices */
export class MonobankAcquiringClient {
    private apiKey: string = '';
    private baseUrl: string = '';

    /**
     * sets value form .env file
     */
    constructor() {
        this.apiKey = getAwsLambdaApiKey();
        this.baseUrl = getAwsLambdaUrl();
    }

    /**
     * @param request contains all needed information for creating invoice
     * @return response with payment page url and id of invoice
     */
    public async createInvoice(request: InvoiceRequest): Promise<InvoiceResponse> {
        try {
            const response = await fetch(`${this.baseUrl}/mono/merchant/invoice/create`, {
                method: 'POST',
                headers: {
                    'x-api-key': this.apiKey,
                },
                body: JSON.stringify(request),
            });

            if (!response.ok) {
                throw new Error('Failed to create invoice, make sure all required fields are filled in');
            }

            const invoice = await response.json();
            return invoice.data as InvoiceResponse;
        } catch (error) {
            console.error(
                `Fetch query with body = ${request} to ${this.baseUrl}/mono/merchant/invoice/create failed with error: ${error}`,
            );
            return {} as InvoiceResponse;
        }
    }
}

export const monobankAcquiringClient = new MonobankAcquiringClient();
