import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'ua',
    lng: 'ua',
    resources: {
        ua: {
            translations: require('./locales/ua/translations.json'),
        },
        pl: {
            translations: require('./locales/pl/translations.json'),
        },
        en: {
            translations: require('./locales/en/translations.json'),
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
});

i18n.languages = ['ua', 'pl', 'en'];

export default i18n;
