import React, { createContext, ReactNode, useContext, useState } from 'react';
import { BuwlSourceSystem, IBuwlCKOContext, Language } from '../model/IBuwlCKOContext';
import { Country } from '../model/ShippingFormDataModel';

const getLanguage = (): Language =>
    new URLSearchParams(window.location.search).get('lang')?.toUpperCase() === 'PL' ? Language.PL : Language.UA;

const getSourceSystem = (): BuwlSourceSystem =>
    new URLSearchParams(window.location.search).get('sourceSystem') === 'buwlweb_PL'
        ? BuwlSourceSystem.BUWL_WEB_PL
        : BuwlSourceSystem.BUWL_WEB_UA;

const getCountry = (): Country => (getLanguage() === Language.PL ? Country.POLAND : Country.UKRAINE);
const getCurrentContext = (): IBuwlCKOContext => ({
    language: getLanguage(),
    sourceSystem: getSourceSystem(),
    country: getCountry(),
    config: {
        REACT_APP_WIX_CART_PL_PAGE_URL: process.env.REACT_APP_WIX_CART_PL_PAGE_URL!,
        REACT_APP_WIX_CART_PAGE_URL: process.env.REACT_APP_WIX_CART_PAGE_URL!,
    },
});

const defaultContextValue: IBuwlCKOContext = getCurrentContext();

const BuwlCKOContext = createContext<{
    buwlContext: IBuwlCKOContext;
    updateContextValue: (newValue: IBuwlCKOContext) => void;
}>({
    buwlContext: defaultContextValue,
    updateContextValue: () => {},
});

export const useBuwlCKOContext = () => useContext(BuwlCKOContext);

export const BuwlCKOContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [buwlContext, setBuwlContext] = useState<IBuwlCKOContext>(getCurrentContext());

    const updateContextValue = (newValue: IBuwlCKOContext): void => setBuwlContext(newValue);

    return <BuwlCKOContext.Provider value={{ buwlContext, updateContextValue }}>{children}</BuwlCKOContext.Provider>;
};
