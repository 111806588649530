import React, { useEffect, useState } from 'react';
import { useBuwlCKOContext } from '../../context/GlobalBuwlCkoAppContext';
import { Language } from '../../model/IBuwlCKOContext';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PLSepaPaymentInfoProps {}

const PLSepaPaymentInfoComponent: React.FC<PLSepaPaymentInfoProps> = () => {
    const { t, i18n } = useTranslation();
    const { buwlContext, updateContextValue } = useBuwlCKOContext();
    const [communicationMethod, setCommunicationMethod] = useState('instagram');

    useEffect(() => {
        if (buwlContext && buwlContext.language) {
            const languageKey = buwlContext.language;
            i18n.changeLanguage(Language[languageKey].toLowerCase());
        }
    }, [buwlContext]);

    const formatCardNumber = (number: string): string => {
        return number.match(/.{1,4}/g)?.join(' ') ?? '';
    };

    const FOP_CARD_NUMBER = '4035200041086510';

    const getFormattedDate = () => {
        const now = Date.now();

        const date = new Date(now);

        const day = String(date.getDate()).padStart(2, '0'); // Day of the month
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (January is 0)
        const year = date.getFullYear(); // Year

        return `${day}.${month}.${year}`;
    };

    return (
        <Box sx={{ maxWidth: 500, mx: 'auto' }}>
            <Typography variant="h5" gutterBottom>
                {t('payment')}
            </Typography>
        </Box>
    );
};

export default PLSepaPaymentInfoComponent;
