// components/NovaPostUkraineAddressComponent.tsx

import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NovaPostShippingAddressProps } from './NovaPostShippingAddressProps';
import { useBuwlCKOContext } from '../../../context/GlobalBuwlCkoAppContext';
import { Branch, City } from '../../../model/NovaPostApiModels';
import { buwlServiceClient } from '../../../clients/BuwlWixServiceClient';

const NovaPostUkraineAddressComponent: React.FC<NovaPostShippingAddressProps> = (props) => {
    const { t } = useTranslation();
    const { buwlContext } = useBuwlCKOContext();
    const [cityQuery, setCityQuery] = useState('');
    const [cityOptions, setCityOptions] = useState<City[]>([]);
    const [branchQuery, setBranchQuery] = useState('');
    const [branchDescription, setBranchDescription] = useState('');
    const [branchOptions, setBranchOptions] = useState<Branch[]>([]);
    const [focusedCity, setFocusedCity] = useState(false);
    const loadingCity = focusedCity && cityOptions.length === 0;
    const [focusedBranch, setFocusedBranch] = useState(false);
    const loadingBranch = focusedBranch && branchOptions.length === 0;

    useEffect(() => {
        const handler = setTimeout(() => {
            if (cityQuery.trim() !== '') {
                setFocusedCity(true);
                buwlServiceClient
                    .fetchCities(cityQuery)
                    .then((value: City[]) => {
                        setCityOptions(value);
                        return;
                    })
                    .catch((reason) => {
                        console.log(reason);
                    });
            } else {
                setBranchQuery('');
                setBranchOptions([]);
            }
        }, 500);

        return () => {
            setFocusedCity(false);
            props.formData.city = '';
            clearTimeout(handler);
        };
    }, [cityQuery]);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (branchQuery.trim() !== '') {
                setFocusedBranch(true);
                buwlServiceClient
                    .fetchBranches(branchQuery, branchDescription)
                    .then((value: Branch[]) => {
                        setBranchOptions(value);
                        return;
                    })
                    .catch((reason) => {
                        console.log(reason);
                    });
            }
        }, 500);

        return () => {
            setFocusedBranch(false);
            props.formData.novapostDep = '';
            setBranchOptions([]);
            clearTimeout(handler);
        };
    }, [branchQuery, branchDescription]);

    const handleCityChange = (event: React.SyntheticEvent, value: string): void => {
        setCityQuery(value);
    };

    const handleBranchChange = (event: React.SyntheticEvent, value: string): void => {
        setBranchDescription(value);
    };

    const selectCity = (event: React.SyntheticEvent, value: City | null): void => {
        if (value) {
            setBranchQuery(value.Ref);
            props.selectCity(event, value);
        }
    };

    const selectBranch = (event: React.SyntheticEvent, value: Branch | null): void => {
        if (value) {
            setFocusedBranch(false);
            props.selectBranch(event, value);
        }
    };

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Autocomplete
                    sx={{ pt: 2, pl: 2 }}
                    fullWidth
                    loading={loadingCity}
                    forcePopupIcon={false}
                    options={cityOptions}
                    isOptionEqualToValue={(option, value) => option.Ref === value.Ref}
                    getOptionLabel={(option) => option.Description}
                    onChange={selectCity}
                    onClose={() => {
                        setFocusedCity(false);
                    }}
                    noOptionsText={t('noAvailableCities')}
                    filterOptions={(options) => options}
                    onInputChange={handleCityChange}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ flexShrink: 0 }} {...props}>
                            <div>
                                {option.Description}{' '}
                                <p style={{ marginTop: 0, marginBottom: 0, opacity: 0.5 }}>
                                    {option.AreaDescription} обл.
                                </p>
                            </div>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('city')}
                            name="city"
                            value={props.formData.city}
                            error={!!props.formErrors.city}
                            helperText={props.formErrors.city}
                            onChange={props.handleFormChange}
                            inputProps={{
                                ...params.inputProps,
                                'data-testid': 'inputCity',
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loadingCity && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Autocomplete
                    sx={{ pt: 2, pl: 2 }}
                    fullWidth
                    loading={loadingBranch}
                    forcePopupIcon={false}
                    isOptionEqualToValue={(option, value) => option.Ref === value.Ref}
                    options={branchOptions}
                    noOptionsText={t('noAvailableBranches')}
                    getOptionLabel={(option) => option.Description}
                    onInputChange={handleBranchChange}
                    onChange={selectBranch}
                    filterOptions={(options) => options}
                    onClose={() => {
                        setFocusedBranch(false);
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ flexShrink: 0 }} {...props}>
                            <div>
                                {option.ShortAddress}{' '}
                                <p style={{ marginTop: 0, marginBottom: 0, opacity: 0.5 }}>№{option.Number}</p>
                            </div>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('novapost')}
                            name="novapostDep"
                            value={props.formData.novapostDep}
                            error={!!props.formErrors.novapostDep}
                            helperText={props.formErrors.novapostDep}
                            onChange={props.handleFormChange}
                            inputProps={{
                                ...params.inputProps,
                                'data-testid': 'inputNovaPostDepartment',
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loadingBranch && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
        </>
    );
};

export default NovaPostUkraineAddressComponent;
