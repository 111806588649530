import React, { useEffect, useRef, useState } from 'react';
import ShippingAddressFormComponent from './components/shippingForm/ShippingAddressFormComponent';
import { Container, CssBaseline, Grid } from '@mui/material';
import OrderSummary from './components/orderSummary/OrderSummary';
import { ShippingOption, WixCart } from './model/cartModel';
import SubmitComponent from './components/SubmitComponent';
import { buwlServiceClient } from './clients/BuwlWixServiceClient';
import { ShippingFormData } from './model/ShippingFormDataModel';
import Mapper from './util/mapper';
import { buwlTransactionsClient } from './clients/PaymentTransactionsClient';
import { useBuwlCKOContext } from './context/GlobalBuwlCkoAppContext';
import { BuwlSourceSystem } from './model/IBuwlCKOContext';
import BuwlHeader from './components/header/HeaderComponent';
import { monobankAcquiringClient } from './clients/MonobankAcquiringClient';
import { formatAmount } from './util/currencyUtils';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [cart, setCart] = useState<WixCart | null>(null);
    const [showSubmit, setShowSubmit] = useState(false);
    const [checkoutId, setCheckoutId] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [submitPageErrorMessage, setSubmitError] = useState('');
    const { buwlContext, updateContextValue } = useBuwlCKOContext();
    const [isRetrievingCart, setRetrievingCart] = useState(true);
    const [shippingCost, setShippingCost] = useState(0);
    const isCartLoading = isRetrievingCart && !cart;

    const handleValidShippingSubmit = () => {
        setShowSubmit(true); // Show the billing section when shipping form is valid and submitted
    };

    const [formData, setFormData] = useState<ShippingFormData>({
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        instagramNickname: '',
        country: '',
        city: '',
        postalCode: '',
        address: '',
        apartmentNumber: '',
        novapostDep: '',
    });

    useEffect(() => {
        if (showSubmit) {
            // window.scrollTo(0, document.body.scrollHeight);
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }
    }, [showSubmit]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const cartId = params.get('cartId');
        const checkout = params.get('checkoutId');

        if (cartId) {
            try {
                buwlServiceClient
                    .getCart(decodeURIComponent(cartId), buwlContext.sourceSystem)
                    .then((value) => {
                        setCart(value);
                        setRetrievingCart(false);
                        if (!value) {
                            buwlContext.sourceSystem === BuwlSourceSystem.BUWL_WEB_UA
                                ? window.location.replace(buwlContext.config.REACT_APP_WIX_CART_PAGE_URL)
                                : window.location.replace(buwlContext.config.REACT_APP_WIX_CART_PL_PAGE_URL);
                        }
                        return;
                    })
                    .catch((reason) => {
                        console.log(reason);
                    });
            } catch (error) {
                setRetrievingCart(false);
                console.error('Error fetching cart:', error);
            }
        } else {
            setRetrievingCart(false);
            buwlContext.language === 'UA'
                ? window.location.replace(buwlContext.config.REACT_APP_WIX_CART_PAGE_URL)
                : window.location.replace(buwlContext.config.REACT_APP_WIX_CART_PL_PAGE_URL);
        }
        if (checkout) {
            setCheckoutId(checkout);
        }
    }, []);

    const handleOrderSubmit = async () => {
        if (cart) {
            setLoading(true);
            const cartRequest: WixCart = {
                cart: { ...cart.cart, selectedShippingOption: { price: shippingCost } as ShippingOption },
            };
            const order = await buwlServiceClient.createOrder(
                Mapper.createOrder(formData, cartRequest, checkoutId, buwlContext.sourceSystem),
                buwlContext.sourceSystem,
            );
            if (!order) {
                setLoading(false);
                setSubmitError('failedToCreateOrder');
                setTimeout(() => {
                    setSubmitError('');
                }, 2000);
            } else {
                const transactions = await buwlTransactionsClient.createPayment(
                    order.order.id,
                    Mapper.createPayments(order),
                    buwlContext.sourceSystem,
                );
                console.log('Payments id ' + transactions?.paymentsIds);
                if (transactions?.paymentsIds && transactions?.orderTransactions) {
                    const invoice = await monobankAcquiringClient.createInvoice(
                        Mapper.createInvoiceRequest(
                            order,
                            transactions,
                            buwlContext.language,
                            buwlContext.sourceSystem,
                        ),
                    );
                    if (invoice?.pageUrl) {
                        console.log('Invoice id ' + invoice.invoiceId);
                        window.location.replace(invoice.pageUrl);
                    } else {
                        console.log(invoice);
                        setLoading(false);
                        setSubmitError('failedToCreateOrder');
                        setTimeout(() => {
                            setSubmitError('');
                        }, 2000);
                    }
                } else {
                    setLoading(false);
                    setSubmitError('failedToCreateOrder');
                    setTimeout(() => {
                        setSubmitError('');
                    }, 2000);
                }
            }
        }
    };

    return (
        <>
            <CssBaseline />
            <BuwlHeader />
            <Container component="main" maxWidth="lg" className="MainComponent">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={7}>
                        {/* Customer and Shipping Details Form */}
                        <ShippingAddressFormComponent
                            onValidShippingSubmit={handleValidShippingSubmit}
                            setFormData={setFormData}
                            formData={formData}
                            setShippingCost={setShippingCost}
                            data-testid="shippingAddressComponent"
                        />
                        {showSubmit && (
                            // Only show the SubmitComponent if showBilling is true
                            <SubmitComponent
                                errorCode={submitPageErrorMessage}
                                isLoading={isLoading}
                                onSubmit={handleOrderSubmit}
                                data-testid="submitComponent"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <OrderSummary
                            wixItems={cart?.cart.lineItems || []}
                            subtotal={cart?.cart.subtotal?.formattedAmount || '0'}
                            shipping={
                                formData.country == 'Poland'
                                    ? formatAmount(shippingCost, buwlContext.sourceSystem)
                                    : t('novaposhtaDeliveryNote')
                            }
                            tax={'0'}
                            total={
                                formatAmount(
                                    parseFloat(cart?.cart.subtotal?.amount!) + shippingCost,
                                    buwlContext.sourceSystem,
                                ) || '0'
                            }
                            isCartLoading={isCartLoading}
                            data-testid="orderSummaryComponent"
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default App;
